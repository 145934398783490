import { memo, FC } from 'react';
import cn from 'classnames';
import { Icon } from 'uikit/Icon';
import { Theme } from 'uikit/common/types';
import { TooltipDepositContentProps } from './types';
import classes from './TooltipDepositContent.module.scss';

export const TooltipDepositContent: FC<TooltipDepositContentProps> = memo(({
  text,
  warn,
  theme = Theme.dark,
  ellipsis = true,
}) => {
  return (
    <span className={cn(classes.content, classes[theme], { [classes.warn]: warn }, { [classes.ellipsisWrapper]: ellipsis })}>
      <span className={cn(classes.text, { [classes.ellipsis]: ellipsis })}>{text}</span>
      {warn && <Icon name="info_w" className={classes.iconWarn} width={11} height={11} />}
    </span>
  );
});