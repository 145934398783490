import React, { memo, FC, useMemo } from 'react';
import { BigNumber } from 'bignumber.js';

import { getFixedDeposit } from 'utils/sdk/utils';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { Tooltip } from 'uikit/Tooltip';
import { TooltipDepositProps } from './types';
import classes from './TooltipDeposit.module.scss';
import { TooltipDepositContent } from './TooltipDepositContent';

export const TooltipDeposit: FC<TooltipDepositProps> = memo(({
  deposit, currency, placement, tooltip = true, warn = false,
}) => {
  const theme = useAppSelector(themeSelector);
  const bnDeposit = useMemo(() => (typeof deposit === 'string' ? new BigNumber(deposit || 0) : deposit), [deposit]);
  const result = useMemo(() => {
    const textCurrency = currency ? ` ${currency}` : '';
    return ({
      tooltip: getFixedDeposit({ deposit: bnDeposit, count: 18 }),
      text: `${getFixedDeposit({ deposit: bnDeposit })}${textCurrency}`,
    });
  }, [bnDeposit, currency]);

  return (
    tooltip
      ? (
        <Tooltip
          theme={theme}
          tooltip={result.tooltip}
          delay={0}
          placement={placement}
          classNamePopover={classes.popover}
          classNamePopoverText={classes.popoverText}
        >
          <TooltipDepositContent theme={theme} text={result.text} warn={warn} />
        </Tooltip>
      )
      : <TooltipDepositContent theme={theme} text={result.text} warn={warn} />
  );
});
