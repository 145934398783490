import React, { memo, FC } from 'react';
import dynamic from 'next/dynamic';
import { Box } from 'uikit/Box';
import { TooltipLinkPopoverProps } from './types';
import classes from './TooltipLinkPopover.module.scss';

const HtmlBox = dynamic(() => import('../../../HtmlBox/HtmlBox'), { ssr: false });

export const TooltipLinkPopover: FC<TooltipLinkPopoverProps> = memo(({ title, text }) => {
  return (
    <Box direction="column" className={classes.tooltip}>
      <div className={classes.description}>{title}</div>
      {text && <HtmlBox text={text} ellipsis={false} />}
    </Box>
  );
});
